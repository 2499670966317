.jobClientWrap {
  position: relative;
}

.jobClient {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .left {
    display: flex;
    flex: 1;
  }

  .jobIcon {
    margin-right: 20px;
  }

  :global {
    /* stylelint-disable-next-line selector-class-pattern */
    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 12px 24px;
      margin-right: 12px;
      font-size: 16px;
      font-weight: 500;
      color: #2378e5;
      background: #fff;
      border: 1px solid #2378e5;
      border-radius: 24px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-btn-primary {
      padding: 12px 32px;
      color: #fff;
      background-color: #2378e5;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ant-btn-primary[disabled] {
      text-shadow: none;
      background: #f5f5f5;
      border-color: #d9d9d9;
      box-shadow: none;
    }
  }

  .saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    background: #f8f9fa;
    border: none;
    border-radius: 10px;
    box-shadow: none;
    transition: none !important;

    &:hover {
      background-color: #f4f5f6 !important;
    }
  }

  .savedButton {
    background-color: #ebf3ff;

    &:hover {
      background-color: #ebf3ff !important;
    }
  }
}

.sharedWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 32px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #000;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: 10px;

  &:hover {
    background-color: #f4f5f6 !important;
  }

  svg {
    margin-right: 6px;
  }

  // &:hover {
  //   color: #2378E5;

  //   path {
  //     stroke: #2378E5;
  //   }
  // }
}

.shareMenu {
  position: absolute;
  top: 42px;
  left: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 252px;
  padding: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #111212;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
}

.copyLink {
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
}

.shareItem {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0 12px;
  font-size: 14px;
  color: #111212;
  cursor: pointer;

  .shareText {
    flex: 1;
    text-align: left;
    white-space: nowrap;
  }

  // &:nth-of-type(1) {
  //   margin: 0 0 5px;
  //   border-bottom: 1px solid #e8e8ea;
  // }

  &:hover {
    background: #f5f9ff;
    border-radius: 8px;
  }

  button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
      width: 20px;
      margin-right: 8px;
    }
  }
}

.chatButton {
  padding: 0 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
