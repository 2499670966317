.chatButton {
  margin-right: 12px;

  /* stylelint-disable-next-line selector-class-pattern */
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
