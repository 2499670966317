.location {
  padding: 28px 0;
  background-color: #fff;

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .address {
    padding: 12px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #3b3c3c;
  }

  .distance {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    svg {
      margin-right: 8px;
    }
  }

  .viewMap {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #2378e5;

    svg {
      margin-right: 6px;
    }
  }

  :global {
    .workLocalHeadTitle {
      color: red;
    }

    #map {
      border-radius: 16px;
    }
  }
}
