.mainSection {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
}

.mainInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 32px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  /* 设置滚动条滑块的样式 */
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 5px;
  }

  /* 设置滚动条轨道的样式 */
  &::-webkit-scrollbar-track {
    background-color: #f8f9fa;
  }

  /* 当鼠标悬停在滚动条滑块上时的样式 */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #ddd;
  }
}

.pubDate {
  padding: 12px 0 0 8px;
  font-size: 12px;
  line-height: 18px;
  color: #7d7d7d;
}

.viewMore {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.viewMoreText {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  background: #fff;
}

.viewMoreText:hover {
  color: #1c70ee;

  svg path {
    stroke: #1c70ee;
  }
}
