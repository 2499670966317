.skillRoot {
  color: var(--neutral-color-text-06121212, var(--neutral-color-text-06121212, #121212));
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}
.skillDesc {
  color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
  margin-bottom: 12px;
}
.skill {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 4px;
  background: var(--neutral-color-text-02-f-6-f-6-f-6, #f6f6f6);
  max-height: 256px;
  overflow-y: auto;
  min-height: 100px;
  gap: 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b8b8b87e;
    border-radius: 10px;
    &:hover {
      background: #b8b8b8;
    }
  }
}

.skillItem {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--neutral-color-line-03-e-4-e-4-e-4, #e4e4e4);
  background: #fff;
  cursor: pointer;
  user-select: none;

  &[data-checked="true"] {
    border-radius: 4px;
    color: #2378e5;
    border: 1px solid var(--brand-color-052378-e-5, #2378e5);
    background: var(--brand-color-02-e-1-eeff, #e1eeff);
  }
  &:not([data-checked="true"]):hover {
    border-color: #1b66c9;
    color: #1b66c9;
  }
}

.selectedHeaderWarm {
  margin-left: 4px;
  color: var(--Functional-color-Warning-02, #ff9500);
}

.selectedArea {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 12px;
}
.selectedItem {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--brand-color-02-e-1-eeff, #e1eeff);
  color: #2378e5;
  border: 1px solid var(--brand-color-02-e-1-eeff, #e1eeff);
  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    path {
      fill: #2378e5;
    }
  }
}
